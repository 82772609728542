import { useEffect, useRef, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { PROD_CLIENT } from 'api/src/common/enums'
import type {
  CreateChangelog,
  CreateChangelogVariables,
  DeleteChangelog,
  DeleteChangelogVariables,
  FindPaginatedChangeLogs,
  FindPaginatedChangeLogsVariables,
  UpdateChangelog,
  UpdateChangelogInput,
  UpdateChangelogVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { apolloCache } from 'src/apolloCache'
import Empty from 'src/components/Library/Empty/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import { useAuth } from 'src/Providers'

import Button from '../Library/Button/Button'
import PageHeader from '../PageHeader/PageHeader'
import ToastMessage from '../ToastMessage/ToastMessage'

import ChangelogItem from './ChangelogItem'
import { createPostButtonClassName } from './common'
import EditChangelogItem from './EditChangeLogItem'
import {
  CREATE_CHANGELOG_ITEM,
  DELETE_CHANGELOG_ITEM,
  PAGINATED_CHANGELOGS_QUERY,
  UPDATE_CHANGELOG_ITEM,
} from './graphql'

const Changelog = () => {
  const { hasRole, currentUser } = useAuth()

  const clientId = currentUser?.membershipData?.clientId

  const canEdit = hasRole(['SUPERADMIN']) && clientId === PROD_CLIENT.STAFFLINK

  const [editId, setEditId] = useState<number | null>(null)

  const [createButtonLoading, setCreateButtonLoading] = useState<boolean>(false)

  const confirmDelete = useConfirm()

  const scrollRef = useRef<HTMLDivElement>(null)

  const queryVariables = { skip: 0, limit: 10 }

  const { data, loading, error, fetchMore, networkStatus } =
    useQuery<FindPaginatedChangeLogs>(PAGINATED_CHANGELOGS_QUERY, {
      variables: queryVariables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    })

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore

  const [createChangelogItemMutation] = useMutation<
    CreateChangelog,
    CreateChangelogVariables
  >(CREATE_CHANGELOG_ITEM, {
    onCompleted: () => {
      setCreateButtonLoading(false)
      toast.success('New post drafted')
    },
    onError: () => {
      setCreateButtonLoading(false)
      toast.error(
        <ToastMessage
          message="There was an error creating your post"
          description={'Please try again or reload the page.'}
        />,
        {
          duration: 5000,
        },
      )
    },
    update: (_, { data: { createChangelog } }) => {
      // we cannot use the boilerplate updateCache function here since
      // the query is sorted by postDate
      const existingData = apolloCache.readQuery<
        FindPaginatedChangeLogs,
        FindPaginatedChangeLogsVariables
      >({
        query: PAGINATED_CHANGELOGS_QUERY,
        variables: queryVariables,
      })

      apolloCache.writeQuery({
        query: PAGINATED_CHANGELOGS_QUERY,
        data: {
          changelogs: {
            ...existingData.changelogs,
            nodes: [createChangelog, ...existingData.changelogs.nodes].sort(
              (a, b) => (a.postDate < b.postDate ? 1 : -1),
            ),
          },
        },
        variables: queryVariables,
      })
    },
  })

  const [updateChangelogItemMutation] = useMutation<
    UpdateChangelog,
    UpdateChangelogVariables
  >(UPDATE_CHANGELOG_ITEM, {
    onCompleted: () => {
      toast.success('Post updated successfully')
    },
    onError: () => {
      toast.error(
        <ToastMessage
          message="There was an error updating your post"
          description={'Please try again or reload the page.'}
        />,
        {
          duration: 5000,
        },
      )
    },
  })

  const [deleteChangelogItemMutation] = useMutation<
    DeleteChangelog,
    DeleteChangelogVariables
  >(DELETE_CHANGELOG_ITEM, {
    onCompleted: () => {
      toast.success('Post deleted successfully')
    },
    onError: () => {
      toast.error(
        <ToastMessage
          message="There was an error deleting your post"
          description={'Please try again or reload the page.'}
        />,
        {
          duration: 5000,
        },
      )
    },
    // for delete use a simple refetch otherwise we can get an issue
    // since we don't load ALL changelogs at once
    refetchQueries: ['FindPaginatedChangeLogs'],
    awaitRefetchQueries: true,
  })

  const onCreateItem = async () => {
    setCreateButtonLoading(true)
    const result = await createChangelogItemMutation({
      variables: {
        input: {
          postDate: new Date().toISOString(),
          status: 'DRAFT',
        },
      },
    })

    setEditId(result.data.createChangelog.id)
  }

  const onUpdateItem = async (id: number, input: UpdateChangelogInput) => {
    if (input.postDate) {
      // do a regular refetch for postDate so we don't get
      // strange results due to the pagination sorted by date
      await updateChangelogItemMutation({
        variables: { id, input },
        refetchQueries: ['FindPaginatedChangeLogs'],
        awaitRefetchQueries: true,
      })
    } else {
      // Update the cache manually for other field updates
      // this is so we don't have to refetch the whole list
      // for a simple status / content change for a single item
      await updateChangelogItemMutation({
        variables: { id, input },
        update: (_, { data: { updateChangelog } }) => {
          const existingData = apolloCache.readQuery<
            FindPaginatedChangeLogs,
            FindPaginatedChangeLogsVariables
          >({
            query: PAGINATED_CHANGELOGS_QUERY,
            variables: queryVariables,
          })

          apolloCache.writeQuery({
            query: PAGINATED_CHANGELOGS_QUERY,
            data: {
              changelogs: {
                ...existingData.changelogs,
                nodes: existingData.changelogs.nodes.map((c) =>
                  c.id === updateChangelog.id ? updateChangelog : c,
                ),
              },
            },
            variables: queryVariables,
          })
        },
      })
    }
  }

  const onDeleteItem = (id: number) => {
    confirmDelete({
      title: 'Delete Post',
      description: 'Are you sure you want to delete this post?',
    }).then(async (isConfirmed) => {
      if (!isConfirmed) return
      setEditId(null)
      await deleteChangelogItemMutation({
        variables: { id },
      })
    })
  }

  useEffect(() => {
    const el = scrollRef.current
    if (!el) return

    // update the 'Create Post' button position based on scroll
    const updateButtonPosition = () => {
      const button = document.querySelector(
        '.fixed.right-5.w-40',
      ) as HTMLElement
      if (button) {
        button.style.top = `${Math.max(20, 80 - el.scrollTop)}px`
      }
    }
    el.addEventListener('scroll', updateButtonPosition)
    return () => el.removeEventListener('scroll', updateButtonPosition)
  }, [])

  useEffect(() => {
    const el = scrollRef.current
    if (!el) return

    const onScroll = async () => {
      // If we’re near the bottom...
      if (el.scrollTop + el.clientHeight >= el.scrollHeight - 100) {
        // ...if we're not already loading, request more
        if (
          !loading &&
          data?.changelogs &&
          !editId &&
          data?.changelogs?.hasNextPage
        ) {
          await fetchMore({
            variables: {
              skip: data.changelogs.nodes.length, // Start where we left off
              limit: 5,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult?.changelogs?.nodes?.length) {
                return previousResult
              }
              return {
                changelogs: {
                  ...fetchMoreResult.changelogs,
                  nodes: [
                    ...previousResult.changelogs.nodes,
                    ...fetchMoreResult.changelogs.nodes,
                  ],
                },
              }
            },
          })
        }
      }
    }

    el.addEventListener('scroll', onScroll)
    return () => el.removeEventListener('scroll', onScroll)
  }, [data, loading, fetchMore, isFetchingMore, editId])

  if (loading && !data) {
    return (
      <div
        className="flex items-center justify-center"
        style={{ minHeight: 'calc(100vh - 80px)' }}
      >
        <LoadingSpinner />
      </div>
    )
  }

  if (error) {
    return <div style={{ color: 'red' }}>Error: {error?.message}</div>
  }

  if (!data?.changelogs?.nodes?.length) {
    return (
      <>
        <PageHeader title="Release Notes" />
        {canEdit && (
          <Button
            onClick={onCreateItem}
            loading={createButtonLoading}
            className={createPostButtonClassName}
          >
            Create Post
          </Button>
        )}
        <div className="flex h-screen items-center justify-center">
          <Empty
            icon={<ClipboardDocumentListIcon className="h-24 w-24" />}
            title={'No release notes found.'}
            description={canEdit ? 'Create a new post to get started.' : ''}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <div className="h-screen overflow-y-scroll" ref={scrollRef}>
        <PageHeader title="Release Notes" />
        {editId ? (
          <EditChangelogItem
            changelog={data.changelogs.nodes.find((c) => c.id === editId)}
            onDeleteItem={onDeleteItem}
            onUpdateItem={onUpdateItem}
            setEditId={setEditId}
          />
        ) : (
          <>
            {canEdit && (
              <Button
                onClick={onCreateItem}
                loading={createButtonLoading}
                className={createPostButtonClassName}
              >
                Create Post
              </Button>
            )}

            {data.changelogs?.nodes?.map((changelog) => (
              <ChangelogItem
                key={changelog.id}
                changelog={changelog}
                canEdit={canEdit}
                onDeleteItem={onDeleteItem}
                onUpdateItem={onUpdateItem}
                setEditId={setEditId}
              />
            ))}
          </>
        )}
        {isFetchingMore && (
          <div className="my-4 flex justify-center">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </>
  )
}

export default Changelog
