import { type FC } from 'react'

import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Divider, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import type { UpdateChangelogInput } from 'types/graphql'

import DocumentEditor from '../Library/DocumentEditor/DocumentEditor'
import Switch from '../Library/Switch/Switch'

import type { ChangeLogType } from './EditChangeLogItem'

interface ChangeLogItemProps {
  changelog: ChangeLogType
  canEdit: boolean
  onDeleteItem: (id: number) => void
  onUpdateItem: (id: number, input: UpdateChangelogInput) => void
  setEditId: (id: number) => void
}

const ChangelogItem: FC<ChangeLogItemProps> = ({
  changelog,
  canEdit,
  onDeleteItem,
  onUpdateItem,
  setEditId,
}) => {
  return (
    <div className="flex flex-col items-center px-2">
      <div className="max-w-5xl">
        <div className="mt-8 flex w-full">
          <div className="mr-5 mt-[32px] flex gap-4">
            <p className="text-sm text-gray-400">
              {dayjs(changelog.postDate).format('MMMM D, YYYY')}
            </p>
            {canEdit && (
              <div className="flex space-x-2">
                <PencilIcon
                  className="h-4 w-4 cursor-pointer text-gray-400 hover:text-gray-900"
                  onClick={() => setEditId(changelog.id)}
                />
                <TrashIcon
                  className="h-4 w-4 cursor-pointer text-gray-400 hover:text-gray-900"
                  onClick={() => onDeleteItem(changelog.id)}
                />

                <Tooltip
                  title={
                    changelog?.status === 'PUBLISHED'
                      ? 'Switch to draft (this will make the item invisible to customers)'
                      : 'Publish Item (this will be visible to everyone)'
                  }
                  className="h-5"
                >
                  <div>
                    <Switch
                      checked={changelog.status === 'PUBLISHED'}
                      onChange={(e) => {
                        onUpdateItem(changelog.id, {
                          status: e.target.checked ? 'PUBLISHED' : 'DRAFT',
                        })
                      }}
                      size="small"
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>

          <div className="flex-1">
            <DocumentEditor
              readerMode={true}
              defaultValue={changelog.content as any}
              holder={`editor-${changelog.id}`}
              storageFolder="changelog"
            />
          </div>
        </div>
        <Divider className="mt-12 w-full" />
      </div>
    </div>
  )
}

export default ChangelogItem
